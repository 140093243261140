import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import SocialLinks from '@components/SocialLinks';
import Img from 'gatsby-image';

const OurVision = () => {
  const data = useStaticQuery(graphql`
    query Image {
      file(relativePath: { eq: "ourVision.png" }) {
        id
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <React.Fragment>
      <div className="fluid-Banner gatsby-image-wrapper">
        <Img fluid={data.file.childImageSharp.fluid} />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="our-vision">
              <div className="post-details">
                <h2
                  id="ourvision"
                  className="vision-header text-on-image-shadow"
                >
                  Our Vision
                </h2>
                <p className="vision-text centered-paragraph">
                  With inspiration from all parts of daily life, CreativeClowns
                  is much more than just the embroideries we see here today. It
                  is a vision of making embroideries easy, accessible and
                  exciting without pointers or limiting rules.
                  <br />
                  <br />
                  The embroideries are created dynamically and the pattern
                  gradually grows over the weave as they are created. The
                  artists share the embroideries and both work on all pieces
                  simultaneously, without knowing the end result beforehand. The
                  embroideries is a reflection of the daily life, where ugly,
                  crazy and beautiful are mixed into the big picture that tells
                  us something about the world we live in today. The artists
                  tackles new shapes and colors with curiosity, and are working
                  with both small, big, flat and 3D shapes. The biggest
                  embroidery so far is over 500 000 stitches big and measures
                  around 1.2x1.2 meter.
                  <br />
                  <br />
                  The future for CreativeClowns is exciting. The artists are
                  planning a revolution within handcraft as embroideries, making
                  it more accessible, interesting and fun. Discussions evolves
                  around creating the best embroidery yarn in the world,
                  improving embroidery weaves and arranging workshops to invite
                  people into the wonderful world of color, inspiration, shapes,
                  spontaneity, patterns, glitter and embroideries!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SocialLinks />
    </React.Fragment>
  );
};

export default OurVision;
