import React from 'react';
import Feed from 'react-instagram-authless-feed';
import ErrorBoundary from '@components/ErrorBoundary';

const Instagram = ({ userName }) => (
  <ErrorBoundary message={"Unable to load Instagram feed"}>
    <div className="container">
      <Feed
        className="instagram-feed"
        userName="creativeclowns"
        classNameLoading="Loading"
        limit="3"
        />
      <a className="link centered-link" href={`//instagram.com/${userName}`}>
        Visit us on Instagram
      </a>
    </div>
  </ErrorBoundary>
);

export default Instagram;
