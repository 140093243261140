import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import DefaultImage from '@components/DefaultImage';

const LatestBlog = ({ data }) => (
  <div className="container blog-section">
    <ul className="latest-blog">
      {data.edges.map(items => (
        <li key={items.node.id}>
          <div className="inner">
            <Link to={`/blog/${items.node.slug}`}>
              {items && items.node.featureImage ? (
                <Img sizes={items.node.featureImage.fluid} />
              ) : (
                <DefaultImage />
              )}
            </Link>
          </div>
          <Link to={`/blog/${items.node.slug}`}>
            <h2>{items.node.title}</h2>
          </Link>
          <div
            className="excerpt"
            dangerouslySetInnerHTML={{
              __html: items.node.description.childMarkdownRemark.excerpt,
            }}
          />
          <div className="read-more-positioning">
            <Link className="primButton blog" to={`/blog/${items.node.slug}`}>
              Read more
            </Link>
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export default LatestBlog;
