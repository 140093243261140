import React from 'react';
import { Link, graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '@components/layout';
import SEO from '@components/seo';
import Banner from '@components/banner';
import LatestBlogs from '@components/latestBlog';
import OurVision from '@components/ourVision';
import Instagram from '@components/Instagram';

const IndexPage = (data) => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <Banner BannerData={data.data.allContentfulHeaderBanner.edges}>
      <div className="bannerButtons">
        <Link className="primButton" to="/blog">
          Join our journey!
        </Link>
        <AnchorLink offset="140" className="secButton" href="#ourvision">
          Our vision
        </AnchorLink>
      </div>
    </Banner>
    <LatestBlogs data={data.data.allContentfulBlogs} />
    <div className="read-more-positioning">
      <Link className="primButton blog" to="/blog">
        See more blog posts
      </Link>
    </div>
    <OurVision />
    <Instagram userName="creativeclowns" />
    <div className="contactus">
      <h2>Don't be a stranger!</h2>
      <div className="mailto-button">
        <Link className="primButton blog" to={'/contact'}>
          Get in touch!
        </Link>
      </div>
    </div>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        url
      }
    }
    allContentfulProduct(limit: 6, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          name
          slug
          image {
            fluid(maxWidth: 1000) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          price
          details {
            childMarkdownRemark {
              excerpt(pruneLength: 140)
            }
          }
        }
      }
    }
    allContentfulHeaderBanner {
      edges {
        node {
          title
          image {
            fluid(maxWidth: 1800) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
    allContentfulBlogs(limit: 2, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          description {
            childMarkdownRemark {
              excerpt(pruneLength: 250)
            }
          }
          featureImage {
            fluid(maxWidth: 1120) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
          categories {
            name
          }
        }
      }
    }
  }
`;
